#root,
html,
body {
    margin: 0;
    padding: 0;
    height: 100%;
}

.app {
    height: 100%;
}

.content {
    padding-top: 60px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    flex: 1;
}

.blur-bg {
    --blurAmount: 5px;
    filter: blur(var(--blurAmount));
    -webkit-filter: blur(var(--blurAmount));
    -moz-filter: blur(var(--blurAmount));
    -o-filter: blur(var(--blurAmount));
    -ms-filter: blur(var(--blurAmount));
    transform: scale(1.1);
}

.grow {
    transition: all 0.15s ease-in-out;
    cursor: pointer;
}
.grow:hover {
    transform: scale(1.1);
}
